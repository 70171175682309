import React from 'react';
import './Container.css';
import { Link as ScrollLink, Element } from 'react-scroll';

const Container = () => {
  return (
    <div className="container">
      <div className="content2">
        <h2 className="section-title9">Entrando hoje no Workshop de Micro Realismo de Pet, você recebe:</h2>
        <div className="rounded-container">
          <div className="image-container">
            <img src="/p2.jpg" alt="Imagem 1" />
          </div>
          <div className="text-container22">
            <h3 className="container-title">Treinamento teórico</h3>
            <p className="container-text">O entendimento das máquinas e materiais, esta é a base essencial para iniciar sua jornada com segurança.</p>
          </div>
        </div>
        <div className="rounded-container">
          <div className="image-container">
            <img src="/p3.jpg" alt="Imagem 2" />
          </div>
          <div className="text-container22">
            <h3 className="container-title">Prática na pele artificial</h3>
            <p className="container-text">A aplicação prática em pele artificial é o método mais didático para solidificar os conhecimentos adquiridos na teoria. Esta abordagem oferece uma experiência tangível e imersiva, permitindo que você desenvolva habilidades fundamentais da tatuagem com confiança e destreza.</p>
          </div>
        </div>
        <div className="rounded-container">
          <div className="image-container">
            <img src="/p1.jpg" alt="Imagem 3" />
          </div>
          <div className="text-container22">
            <h3 className="container-title">Prática na pele humana</h3>
            <p className="container-text">Nesta fase, você vai me acompanhar enquanto eu guio você através de todas as técnicas de aplicação. Isso garantirá que você tenha a confiança necessária para proporcionar uma experiência excepcional aos seus clientes.</p>
          </div>
        </div>
        <div className="centered-image">
          <img src="/tattoo.jpg" alt="Imagem Centralizada" />
        </div>
        <p className="text3">Trabalhos feitos no workshop</p>
        <h2 className="text3">E não será apenas isso</h2>
        <p className="text3">Te garanto: A única forma de você evoluir suas tattoos de pet e se tornar referência no mercado é melhorando a sua técnica e aprendendo com quem domina o micro realismo. Por isso, teremos…</p>
        <div className="rounded-container">
          <div className="image-container">
            <img src="/p4.jpg" alt="Imagem 1" />
          </div>
          <div className="text-container22">
            <h3 className="container-title">Comunidade Micro Realismo de Pet</h3>
            <p className="container-text">Um espaço para você compartilhar com outros tatuadores os teus trabalhos, pedir feedbacks, trocar ideias e fazer networking com outros profissionais. Além disso, o professor está presente no grupo para fornecer assistência e orientação.</p>
          </div>
        </div>
        <a href="https://pay.hotmart.com/S86515892R" className="button-linka9" target="_blank" rel="noopener noreferrer">DESBLOQUEAR O ACESSO AGORA</a>
      </div>
    </div>
  );
}

export default Container;
