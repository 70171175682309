import React from 'react';
import './App.css';
import Hero from './components/Hero';
import './components/Hero.css';
import Segunda from './components/Segunda';
import './components/Segunda.css';
import Arte from './components/Arte';
import './components/Arte.css'
import Container from './components/Container';
import './components/Container.css'
import Feed from './components/Feed';
import './components/Feed.css'
import Caixa from './components/Caixa';
import './components/Caixa.css'
import Promo from './components/Promo';
import './components/Promo.css'
import Garantia from './components/Garantia';
import './components/Garantia.css'
import Navbar from './components/Navbar';
import Tela from './components/Tela';

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <Tela />
      <Hero />
      <Segunda />
      <Arte />
      <Container />
      <Feed />
      <Caixa />
      <Promo />
      <Garantia />
    </div>
  );
}

export default App;