import React, { useEffect, useState } from 'react';
import './Navbar.css';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <div className="logo" onClick={scrollToTop}>
        <img src="/logon.svg" alt="Logo" className={scrolled ? 'small-logo' : 'big-logo'} />
      </div>
      <div className="instagram-button">
        <a href="https://www.instagram.com/marcossuzin.art/" target="_blank" rel="noopener noreferrer">
          <img src="/insta3.svg" alt="Instagram Logo" />
        </a>
      </div>
    </div>
  );
}

export default Navbar;
