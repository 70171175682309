import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Segunda.css';
import { Link as ScrollLink, Element } from 'react-scroll';

const Segunda = () => {
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
  };

  if (window.innerWidth <= 768) {
    settings.slidesToShow = 2; // Mostra 2 slides em telas menores que 768px
  } else {
    settings.slidesToShow = 3; // Mostra 3 slides em telas maiores que 768px
  }

  return (
    <div className="segunda">
      <div className="content4">
        <h1 className="title2">Estamos passando pela maior oportunidade do mercado de tattoo dos últimos 12 meses</h1>
        <p className="texto">O primeiro semestre de 2023 foi o de maior faturamento para 99% dos tatuadores especializados em micro realismo de pet. </p>
        <p className="texto">Mas o que não te falaram, é que o mercado de pet vai crescer ainda mais já no fim de 2023. Cada vez mais, os amantes dos animais estão optando por eternizar seus queridos animais de estimação em arte sob a pele. </p>
        <p className="texto">Essas tatuagens não são apenas obras de arte, mas testemunhos da profundidade dos laços que unem humanos e pets. Cada marca na pele é uma celebração  da alegria e amor que nossos animais de estimação trazem às nossas vidas. </p>
        <div className="images">
          <img src="/pet.png" alt="Imagem" />
        </div>
        <p className="texto">Gerando uma demanda ainda maior por esse estilo de tattoo. Nas últimas semanas, essas foram algumas mensagens que eu recebi de pessoas dispostas a ter seu pet marcado na pele:</p>
        <div className="carousel">
          <Slider {...settings}>
            <div>
              <img src="/clien1.jpg" alt="Imagem 1" />
            </div>
            <div>
              <img src="/clin2.jpg" alt="Imagem 2" />
            </div>
            <div>
              <img src="/clien3.jpg" alt="Imagem 3" />
            </div>
            <div>
              <img src="/clien4.jpg" alt="Imagem 4" />
            </div>
            <div>
              <img src="/clien5.jpg" alt="Imagem 5" />
            </div>
            <div>
              <img src="/clien6.jpg" alt="Imagem 5" />
            </div>
            <div>
              <img src="/clien8.jpg" alt="Imagem 5" />
            </div>
            <div>
              <img src="/clien9.jpg" alt="Imagem 5" />
            </div>
          </Slider>
        </div>
        <p className="texto">Você pode estar disposto a pegar sua fatia desse bolo que cresce cada vez mais… Ou só ficar olhando outros tatuadores aproveitarem a oportunidade na sua frente. A escolha é sua!</p>
        <a href="https://pay.hotmart.com/S86515892R" className="button-linka9" target="_blank" rel="noopener noreferrer">DESBLOQUEAR O ACESSO AGORA</a>
      </div>
    </div>
  );
}

export default Segunda;