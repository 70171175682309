import React from 'react';
import './Hero.css';
import '@fontsource/cinzel';
import '@fontsource/roboto-condensed';
import { Link as ScrollLink, Element } from 'react-scroll';

const Hero = () => {
  return (
    <div className="hero">
      <div className="content-container">
        <div className="text-container">
          <h2><span className="titleh">Aprenda o traço a traço para tatuar pets em micro realismo e se torne um profissional referência neste nicho</span></h2>
          <p><span className="text">Ao clicar no botão abaixo você terá acesso às técnicas por trás de cada tattoo de pet em micro realismo que vão te permitir aumentar seu portfólio ainda em 2023. Só entre se você estiver disposto a dominar o mercado nos próximos meses.</span></p>
          <a href="https://pay.hotmart.com/S86515892R" className="button-linka9" target="_blank" rel="noopener noreferrer">DESBLOQUEAR O ACESSO AGORA</a>
        </div>
        <div className="image-containerH imageo">
          <img src="/tattoo.jpg" alt="Imagem" />
        </div>
      </div>
    </div>
  );
}

export default Hero;
