import React from 'react'
import './Arte.css';
import { Link as ScrollLink, Element } from 'react-scroll';

function Arte() {
  return (
    <div className="arte">
      <div className="content">
        <h1 className="title3">Você vai aprender as técnicas para desenvolver trabalhos como esses:</h1>
        <div className="images-containerd">
          <img src="/tat1.jpg" alt="Imagem" />
          <img src="/tat2.jpg" alt="Imagem" />
          <img src="/tat3.jpg" alt="Imagem" />
          <img src="/tat4.jpg" alt="Imagem" />
        </div>
        <a href="https://pay.hotmart.com/S86515892R" className="button-linka9" target="_blank" rel="noopener noreferrer">DESBLOQUEAR O ACESSO AGORA</a>
      </div>
    </div>
  );
}

export default Arte;
