import React from 'react';
import './Garantia.css';
import { Link as ScrollLink, Element } from 'react-scroll';

const Garantia = () => {
  return (
    <div className="garantia">
      <div className="contentg">
        <h1 className="title7">Você não vai correr o risco de se decepcionar!</h1>
        <div className="imageg">
          <img src="/selo1.svg" alt="Imagem" />
          <img src="/selo2.svg" alt="Imagem" />
        </div>
        <p className="text7">O processamento de pagamento e a disponibilização do conteúdo são realizados através da plataforma Hotmart, que não apenas garante 100% de segurança, mas também oferece uma garantia incondicional: caso não fique satisfeito em até 7 dias, o seu investimento será integralmente reembolsado.</p>
        <p className="text8">Além disso, ao concluir o curso, você receberá um Certificado de Participação como reconhecimento pelo seu empenho e aprendizado.</p>
      </div>
    </div>
  );
}

export default Garantia;