import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Feed.css';
import { Link as ScrollLink, Element } from 'react-scroll';

const Feed = () => {
  const settings2 = {
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
  };

  if (window.innerWidth <= 768) {
    settings2.slidesToShow = 2; // Mostra 2 slides em telas menores que 768px
  } else {
    settings2.slidesToShow = 3; // Mostra 3 slides em telas maiores que 768px
  }

  return (
    <div className="feed">
      <div className="content3">
        <h1 className="title5">É isso que você pode esperar receber dos seus clientes ainda em 2023…</h1>
        <div className="carousel">
          <Slider {...settings2}>
            <div>
              <img src="/ag1.jpg" alt="Imagem 1" />
            </div>
            <div>
              <img src="/ag2.jpg" alt="Imagem 2" />
            </div>
            <div>
              <img src="/ag3.jpg" alt="Imagem 3" />
            </div>
            <div>
              <img src="/ag4.jpg" alt="Imagem 4" />
            </div>
            <div>
              <img src="/ag5.jpg" alt="Imagem 5" />
            </div>
            <div>
              <img src="/ag6.jpg" alt="Imagem 5" />
            </div>
          </Slider>
        </div>
        <a href="https://pay.hotmart.com/S86515892R" className="button-linka9" target="_blank" rel="noopener noreferrer">DESBLOQUEAR O ACESSO AGORA</a>
      </div>
    </div>
  );
}

export default Feed;
