import React, { useState, useEffect } from 'react';
import './Tela.css';
import '@fontsource/spectral'; // Importa a fonte Spectral
import '@fontsource/playfair-display';
import { Link as ScrollLink, Element } from 'react-scroll';

const Tela = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="tela">
      <div className="contentt">
        <div className="textt">
          <h2>Técnicas Exclusivas</h2>
          <ul>
            <li>Dois em um - gato e cachorro</li>
            <li>Como usar o preto puro</li>
            <li>Sombra e textura</li>
            <li>Reprodução realista</li>
            <li>Pelagem em detalhes</li>
          </ul>
          <a href="https://pay.hotmart.com/S86515892R" className="button-linka9" target="_blank" rel="noopener noreferrer">DESBLOQUEAR O ACESSO AGORA</a>
        </div>
      </div>
      {!isSmallScreen && (
        <div className="imaget">
          <img src="/hero2.svg" alt="Imagem" />
        </div>
      )}
      {isSmallScreen && (
        <div className="mobileImage">
          <img src="/2.png" alt="Imagem para telas pequenas" />
        </div>
      )}
    </div>
  );
}

export default Tela;
