import React from 'react';
import './Caixa.css';
import { Link as ScrollLink, Element } from 'react-scroll';

const Caixa = () => {
  return (
    <div className="caixa">
      <div className="content">
        <h2 className="section-title2">Para quem é o Workshop de Micro Realismo: Pet?</h2>
        <div className="container-row">
          <div className="container2">
            <div className="check-symbol">✔</div>
            <p className="container-text2">Para quem está iniciando como tatuador e quer começar a carreira com uma técnica que está em alta demandada pelo mercado.</p>
          </div>
          <div className="container2">
            <div className="check-symbol">✔</div>
            <p className="container-text2">Para tatuadores que desejam ter uma conexão maior com seus clientes, tendo em vista a relação de significado associado aos trabalhos de pet.</p>
          </div>
        </div>
        <div className="container-row">
          <div className="container2">
            <div className="check-symbol">✔</div>
            <p className="container-text2">Para tatuadores que já conhecem o micro realismo e querem se aprofundar nas técnicas de tattoo de pet.</p>
          </div>
          <div className="container2">
            <div className="check-symbol">✔</div>
            <p className="container-text2">Para tatuadores que desejam aumentar seu portfólio oferecendo ao cliente tattoos menores que se encaixam em qualquer parte do corpo. </p>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Caixa;