import React from 'react';
import './Promo.css';
import { Link as ScrollLink, Element } from 'react-scroll';

const Promo = () => {
  return (
    <Element name="promo" className="target-section">
      <div className="promo">
        <div className="contentp">
          <h2 className="title6">Comece hoje mesmo!!</h2>
          <div className="rounded-containerp">
            <p className="titlep">Condição exclusiva</p>
            <p>De <span className="discounted">R$ 997,00</span> por:</p>
            <p>12x de <span className="price">R$ 69,56</span></p>
            <p>ou R$ 697,00 à vista</p>
            <ul className="checklist">
              <li>Garantia</li>
              <li>Pagamento seguro</li>
              <li>Acesso por 1 ano</li>
            </ul>
          </div>
        </div>
        <div className="contentp2">
        <a href="https://pay.hotmart.com/S86515892R" className="button-linka9" target="_blank" rel="noopener noreferrer">DESBLOQUEAR O ACESSO AGORA</a>
        </div>
      </div>
    </Element>
  );
}

export default Promo;
